<template>
  <div>
    <div class="row filtersettings-page">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('FILTERING.SETTINGS.FILTER_SETTINGS')">
          <template v-slot:body>
            <p>{{ $t("BRANDING.FORM_DESC") }}</p>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')"
                    @input="onChangeDomain"
                    id="select-domain">
                  </model-list-select>
                </b-form-group>
              </div>
              <div class="col-sm-6"></div>
            </div>
            <div class="my-2 row">
              <div class="col-lg-3 setting-form">
                <label>{{
                  $t("FILTERING.SETTINGS.ENABLE_AUTOWHITELIST")
                }}</label>
              </div>
              <div class="col-lg-9 col-xl-4">
                <b-form-select
                  v-model="settings.enableAutowhitelist.selected"
                  value-field="value"
                  text-field="name"
                  :options="enableAutowhitelistData"
                  :disabled="canAdd == false"
                ></b-form-select>
              </div>
            </div>
	    <div class="my-2 row">
              <div class="col-lg-3 setting-form">
                <label>{{ $t("FILTERING.SETTINGS.FILTER_SENSITIVITY") }}</label>
              </div>
              <div class="col-lg-9 col-xl-4">
                <b-form-select
                  v-model="settings.sensitivity.selected"
                  value-field="value"
                  text-field="name"
                  :options="sensitivityData"
                  :disabled="canAdd == false"
                ></b-form-select>
              </div>
            </div>
            <div class="my-2 row">
              <div class="col-lg-3 setting-form">
                <label>{{ $t("FILTERING.SETTINGS.KEEP_SPAM_FOR") }}</label>
              </div>
              <div class="col-lg-9 col-xl-4">
                <b-form-select
                  v-model="settings.keepspam.selected"
                  value-field="value"
                  text-field="name"
                  :options="settings.keepspam.data"
                  :disabled="canAdd == false"
                ></b-form-select>
              </div>
            </div>
            <div class="my-2 row">
              <div class="col-lg-3 setting-form">
                <label>{{ $t("FILTERING.SETTINGS.SEND_SPAM_REPORT") }}</label>
              </div>
              <div class="col-lg-9 col-xl-4">
                <b-form-select
                  v-model="settings.sendspamreport.selected"
                  value-field="value"
                  text-field="name"
                  :options="sendspamreportData"
                  :disabled="canAdd == false"
                ></b-form-select>
              </div>
            </div>
            <div class="my-2 row">
              <div class="col-lg-3 setting-form">
                <label>{{
                  $t("FILTERING.SETTINGS.SPAM_REPORT_CONTENT")
                }}</label>
              </div>
              <div class="col-lg-9 col-xl-4">
                <b-form-select
                  v-model="settings.spamreportcontent.selected"
                  value-field="value"
                  text-field="name"
                  :options="spamreportcontentData"
                  :disabled="canAdd == false"
                ></b-form-select>
              </div>
            </div>
            <div class="my-2 row">
              <div class="col-lg-3 setting-form">
                <label>{{ $t("FILTERING.SETTINGS.SPAM_REPORT_FORMAT") }}</label>
              </div>
              <div class="col-lg-9 col-xl-4">
                <b-form-select
                  v-model="settings.monitortext.selected"
                  value-field="value"
                  text-field="name"
                  :options="monitortextData"
                  :disabled="canAdd == false"
                ></b-form-select>
              </div>
            </div>
            <div class="my-2 row">
              <div class="col-lg-3 setting-form">
                <label>{{
                  $t("FILTERING.SETTINGS.LOGIN_LOCATION_LIMIT")
                }}</label>
              </div>
              <div class="col-lg-9 col-xl-4">
                <b-form-select
                  v-model="settings.loginlocationcnt.selected"
                  value-field="value"
                  text-field="name"
                  :options="settings.loginlocationcnt.data"
                  :disabled="canAdd == false"
                ></b-form-select>
              </div>
            </div>
          </template>
          <template v-slot:foot>
            <b-button
              variant="primary"
              @click="updateSetting()"
              :disabled="canAdd == false"
            >
              {{ $t("BRANDING.UPDATE_SETTINGS") }}
            </b-button>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";

export default {
  name: "settings",
  components: {
    KTPortlet,
  },
  data: () => ({
    selectDomain: {
      selectedDomain: {},
      allDomains: [],
    },
    settings: {
      enableAutowhitelist: {
        selected: 1,
      },
      sensitivity: {
        selected: 0,
      },
      keepspam: {
        selected: 0,
        data: [],
      },
      sendspamreport: {
        selected: 0,
      },
      spamreportcontent: {
        selected: 1,
      },
      monitortext: {
        selected: 1,
      },
      loginlocationcnt: {
        selected: 1,
        data: [
          { name: "1", value: 1 },
          { name: "2", value: 2 },
          { name: "3", value: 3 },
          { name: "4", value: 4 },
          { name: "5", value: 5 },
        ],
      },
      filtering_type: "",
    },
  }),
  computed: {
    enableAutowhitelistData: function () {
      return [
        { name: this.$t("COMMON.YES"), value: 1 },
        { name: this.$t("COMMON.NO"), value: 2 },
      ];
    },
    sensitivityData: function () {
      return [
        { name: this.$t("FILTERING.SETTINGS.NORMAL_SENSITIVITY"), value: 0 },
        { name: "1 - " + this.$t("FILTERING.SETTINGS.MOST_SPAM"), value: 1 },
        { name: "2", value: 2 },
        { name: "3", value: 3 },
        { name: "4", value: 4 },
        { name: "5", value: 10 },
        { name: "6", value: 11 },
        { name: "7", value: 12 },
        { name: "8", value: 13 },
        { name: "9", value: 14 },
        {
          name: "10 - " + this.$t("FILTERING.SETTINGS.MOST_MESSAGE"),
          value: 15,
        },
      ];
    },
    sendspamreportData: function () {
      return [
        { name: this.$t("FILTERING.SETTINGS.DONT_SEND"), value: 0 },
        { name: this.$t("FILTERING.SETTINGS.EVERY_HOUR"), value: 1 },
        {
          name: this.$t("FILTERING.SETTINGS.EVERY_HOURS", { num: "2" }),
          value: 2,
        },
        {
          name: this.$t("FILTERING.SETTINGS.EVERY_HOURS", { num: "3" }),
          value: 3,
        },
        {
          name: this.$t("FILTERING.SETTINGS.EVERY_HOURS", { num: "4" }),
          value: 4,
        },
        {
          name: this.$t("FILTERING.SETTINGS.EVERY_HOURS", { num: "6" }),
          value: 6,
        },
        {
          name: this.$t("FILTERING.SETTINGS.EVERY_HOURS", { num: "8" }),
          value: 8,
        },
        {
          name: this.$t("FILTERING.SETTINGS.EVERY_HOURS", { num: "12" }),
          value: 12,
        },
        {
          name: this.$t("FILTERING.SETTINGS.EVERY_HOURS", { num: "24" }),
          value: 24,
        },
      ];
    },
    spamreportcontentData: function () {
      return [
        { name: this.$t("FILTERING.SETTINGS.ALL_SPAM_MESSAGES"), value: 1 },
        { name: this.$t("FILTERING.SETTINGS.LATEST_SPAM_MESSAGES"), value: 2 },
      ];
    },
    monitortextData: function () {
      return [
        { name: this.$t("FILTERING.SETTINGS.HTML_TEXT"), value: 1 },
        { name: this.$t("FILTERING.SETTINGS.HTML_ONLY"), value: 2 },
        { name: this.$t("FILTERING.SETTINGS.TEXT_ONLY"), value: 3 },
      ];
    },
    canAdd: function () {
      return this.hasPermission("filtering", 5);
    },
  },
  created() {
    for (let i = 1; i < 31; i++) {
      if (i == 1) {
        this.settings.keepspam.data.push({
          name: i + this.$t("COMMON.DAY"),
          value: i,
        });
      } else {
        this.settings.keepspam.data.push({
          name: i + this.$t("COMMON.DAYS"),
          value: i,
        });
      }
    }
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0];
        this.getSettingsReqest(this.selectDomain.selectedDomain.domain);
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.FILTERING") + " -> " + this.$t("MENU.SETTINGS") },
    ]);
  },
  methods: {
    /* ---------read setting---------- */
    getSettingsReqest(domain) {
      postRequest({
        action: "getDomainFilters",
        token: localStorage.id_token,
        domain: domain,
        gethtml: 0,
      }).then((res) => {
        if (res.returncode) {
          this.settings.enableAutowhitelist.selected =
            res.returndata.enable_autowhitelist;
          this.settings.sensitivity.selected = res.returndata.sensitivity;
          this.settings.keepspam.selected = res.returndata.keepspam;
          this.settings.sendspamreport.selected = res.returndata.sendspamreport;
          this.settings.spamreportcontent.selected =
            res.returndata.spamreportcontent;
          this.settings.monitortext.selected = res.returndata.monitortext;
          this.settings.loginlocationcnt.selected =
            res.returndata.loginlocationcnt;
          this.settings.filtering_type = res.returndata.filtering_type;
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    getFilterSettingsForSelectedDomain(selectedDomain) {
      this.getSettingsReqest(selectedDomain);
    },
    /* ---------- update setting ---------- */
    updateSetting() {
      postRequest({
        action: "updateFilterSettings",
        token: localStorage.id_token,
        domain: this.selectDomain.selectedDomain.domain,
        filtering_type: this.settings.filtering_type,
        enable_autowhitelist: this.settings.enableAutowhitelist.selected,
        sensitivity: this.settings.sensitivity.selected,
        keepspam: this.settings.keepspam.selected,
        sendspamreport: this.settings.sendspamreport.selected,
        spamreportcontent: this.settings.spamreportcontent.selected,
        monitortext: this.settings.monitortext.selected,
        loginlocationcnt: this.settings.loginlocationcnt.selected,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          this.getSettingsReqest(this.selectDomain.selectedDomain.domain);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
    /* ---- change domain dropdown ---- */
    onChangeDomain(newValue) {
      this.getFilterSettingsForSelectedDomain(newValue.domain);
    },
  },
};
</script>
